<template>
  <div style="text-align: left">
    <v-expansion-panels accordion v-if="model.accordion" class="pa-0">
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header dense>
          <template v-slot:default="{ open }">
            <div class="my-0 pt-1">{{ model.title }}</div>
          </template></v-expansion-panel-header
        >
        <v-expansion-panel-content class="accordion_content" style="padding: 0">
          <portal-target :name="`array-content-` + model.name" style="padding: 0" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <div class="text-overline my-0 pt-1">{{ model.title }}</div>
      <portal-target :name="`array-content-` + model.name" />
    </div>
    <portal :to="`array-content-` + model.name">
      <v-row v-for="(el, i) in list" :key="i" no-gutters class="mb-4">
        <v-col class="flex-grow-1 flex-shrink-0 a-input-array">
          <a-input-base-object
            class="a-input-array-item array-items"
            :value="list[i]"
            :config="{ dense: true, hideDetails: true }"
            :readonly="![-2, i].includes(changed) || readonly"
            :model="model"
            @commit="commit($event, i)"
            @change="changed = i"
          />
          <hr v-if="i < list.length - 1" class="mb-0 mt-3" />
        </v-col>
        <v-col v-if="!readonly" class="flex-grow-0 flex-shrink-1">
          <div class="pt-2">
            <v-btn elevation="0" fab small color="red" bottom @click="onClickDel(i)" :disabled="![-2, i].includes(changed)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="text-left mt-1">
        <v-btn v-if="!readonly" elevation="2" fab x-small color="green" top left @click="onClickAdd" :disabled="![-2].includes(changed)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </portal>
  </div>
</template>

<script>
import { getAccess, libChecks } from "@/components/mixings";
export default {
  components: {
    //ButtonItem: () => import("./button"),
  },
  mixins: [libChecks, getAccess],
  props: {
    value: Array,
    model: Object,

    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      changed: -2,
      isChanged: false,
      insert: false,
      newData: null,
    };
  },
  computed: {
    list: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        let res = [];
        if (this.value) res = this.value;
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
  },
  created() {
    //    this.reset();
  },
  watch: {
    value(v) {
      this.reset();
    },
    "object.id"(v) {},
  },
  methods: {
    onValid(e) {
      this.validate($event);
    },

    async reset() {
      this.changed = this.changed == this.list.length - 1 ? this.changed : -2;
    },
    onChange(i) {
      console.log("onchange", i);
      this.changed = i;
    },
    commit(e, i = -1) {
      let d = [...this.list];
      if (i == -1) {
        d = [...d, e];
      } else d[i] = e;
      this.changed = -2;
      this.list = d;
      //this.$emit("input", d);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
    },
    onClickAdd() {
      this.list.push({});
      this.changed = this.list.length - 1;
      return;
    },
  },
};
</script>

<style lang="scss">
@mixin table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover) {
  .a-input-array1 {
    .a-input-array-item.array-items {
      background-color: $backgroundColor;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
    .a-input-array-item.array-items:nth-child(odd) {
      background-color: $backgroundColorSecond;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
  }
}

.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
</style>
