<template>
  <div>
    <dialog-edit2 :value="value || isShow" @input="_close">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ type }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col sm="3" class="pa-0">
          <v-list dense>
            <v-list-item-group v-model="itemSelected" color="primary">
              <v-list-item v-for="(item, i) of rows" :key="item.name"> {{ i }} - {{ item.name }} </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col sm="9">
          <v-row v-if="itemSelected >= 0 && data && !loading">
            <a-form-modelCols
              v-model="rows[itemSelected]"
              class="pb-1 mx-1 pt-1"
              :model="model"
              :values="rows[itemSelected]"
              :errors="errors"
              :config="{ dense: true, readonly, hideDetails: true }"
              @validate="
                doWatch($event);
                validate($event);
              "
            />
          </v-row>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="readonly" @click="_submit()"></a-btn-save>
        <v-btn @click="_close">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    dataTable: Array,
    value: Boolean,
    id: Number,
    configForm: { type: Object, default: () => {} },
    opt: { type: String, default: "" },
  },
  data() {
    return {
      model: [],
      loaded: false,
      itemSelected: 0,
      row: {},
      rows: [],
      data: {},
      type: null,
      isShow: false,
      m: {
        types: ["string", "number", "phone", "select", "selectapi", "table", "id", "datetime", "date"],
        form: [
          { name: "name", type: "string" },
          { name: "title", type: "string" },
          { name: "type", type: "select", sourceList: "types" },
          { name: "sourceList", type: "string" },
          { name: "sortable", type: "boolean", options: "boolean", default: true },
          { name: "hidden", type: "boolean", options: "boolean", default: true },
          { name: "width", type: "numberstr" },
          { name: "validator", type: "variant", default: [] },
          { name: "hint", type: "text" },
        ],
        config: {
          default: {
            fields: "name,title,type,validator,width,sortable,hidden",
            fieldsRO: "",
            fieldsForm: "name#5,title#7,width#2,type#4,sortable#3,hidden#3,validator",
          },
          list: {
            fields: "name,title,type,validator,width,sortable,hidden",
            fieldsRO: "",
            fieldsForm: "name#5,title#7,width#2,type#4,sortable#3,hidden#3",
          },
          form: {
            fields: "name,title,type,validator,width,sortable,hidden,hint",
            fieldsRO: "",
            fieldsForm: "name#5,title#7,width#2,type#4,validator,hint",
          },
        },
      },
    };
  },
  computed: {
    config() {
      return JSON.parse(JSON.stringify(this.m.config?.[this.type] || this.m.config.default || {}));
    },

    title() {
      return this.m?.title || "";
    },

    canDelete() {
      return this.opt.includes("delete");
    },
    readonly() {
      return this.opt.includes("readonly");
    },
  },
  created() {
    this.loaded = false;
  },
  watch: {
    id(v) {
      if (v || v === 0) this.row = this.dataTable.find(el => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find(el => el.id == this.id);
      }
    },
    isShow(v) {
      if (v) {
      } else {
        console.log("reset show");
        this.resetForm();
      }
    },
    itemSelected(v) {
      this.loadData(v);
    },
  },
  methods: {
    show(opts = {}) {
      this.type = opts.type;
      this.fetchData(opts.data);
      // Once we set our config, we tell the popup modal to open
      this.isShow = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    afterFetchData() {
      let t = 0;
      if (this.m?.config?.watch) {
        t = this.data?.[this.m.config.watch] || 0;
      }
      this.getModel(this.type);
      this.loaded = true;
    },
    resetForm() {
      this.model = [];
      this.data = {};
      this.rows = {};
    },
    initData(data) {
      this.resetForm();
      this.itemSelected = -1;

      this.rows = JSON.parse(JSON.stringify(data || []));
    },
    loadData(i) {
      this.data = null;
      if (i < 0) return;
      this.data = {};
      this.row = this.rows[i];

      this.loading = !false;
      for (const name in this.row) {
        this.$set(this.data, name, this.row[name]);
      }
      this.loading = false;
    },

    async fetchData(data) {
      this.loading = true;
      this.initData(data);
      this.afterFetchData();
    },
    beforeSave() {
      //if (this.data.status === null) this.data.status = 0;
    },
    post() {
      if (this.isShow) {
        this.resolvePromise(this.rows);
        this.isShow = false;
      } else {
        this.$emit("save", this.rows);

        this.$emit("input");
      }
    },

    _submit() {
      if (this.readonly) return;
      this.submit();
    },
    doWatch(e) {
      //console.log({ e, wathc: this.m?.config?.watch, value: this.data?.[e] });
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
    },
    getModel(t) {
      let config = Object.assign({}, this.config);
      if (t) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
      model.forEach(el => {
        el.title = el.title || el.name;
      });
      this.fillFormFromModel(model);
      let model_ = [];
      this.modelForm.forEach(f => {
        model_.push(model.find(m => m.name == f.name));
      });
      console.log(model_);
      this.model = model_;
    },
    _close() {
      if (this.isShow) {
        this.isShow = false;
        this.loaded = false;
        this.resolvePromise(false);
      } else this.$emit("input");
    },
  },
};
</script>
